<!--
 * @Author: 牛牪犇
 * @Date: 2023-05-22 11:05:22
 * @LastEditTime: 2023-07-23 18:39:36
 * @LastEditors: 王有毒
 * @Description:
 * @FilePath: \edub_tob_pc\src\views\partnerPage\mine\learn\audioPlayer.vue
 *
-->
<template>
  <div class="audio-box" @click.stop="palyAudio">
    <img
      v-if="audioImg"
      class="audio-icon"
      src="@/assets/img/homeSeventh/audio-parse.png"
    />
    <img v-else class="audio-icon" src="@/assets/img/partner/audio.png" />
    <img
      v-if="audioImg"
      class="play"
      src="@/assets/img/homeSeventh/audio.gif"
    />
    <img v-else class="play" src="@/assets/img/homeSeventh/audio.png" />
    <span>{{ countDownTime > 0 ? countDownTime : audioDuration }}s</span>
  </div>
</template>
<script >
export default {
  props: {
    audioUrl: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      workDetail: {},
      audioImg: false,
      audio: null,
      countDownTime: 0,
      countDownItme: "",
      audioDuration: 0, // 音频秒数
    };
  },
  created() {},
  mounted() {
    this.audio = new Audio(this.audioUrl.url);
    console.log(this.audioUrl.url);
    this.audio.onloadedmetadata = () => {
      this.audioDuration = parseInt(this.audio.duration);
    };
  },
  methods: {
    beforeDestroy() {
      this.audio.pause();
      clearInterval(this.countDownItme);
      this.countDownItme = 0;
    },
    palyAudio() {
      const _this = this;
      if (this.audio.paused) {
        console.log(this.audio);
        this.audio.load();
        this.audio.play();
        this.countDownTime = this.audioDuration;
        this.countDownItme = setInterval(() => {
          if (this.countDownTime > 0) {
            this.countDownTime--;
          }
        }, 1000);
        this.audioImg = true;
        this.audio.onended = () => {
          clearInterval(this.countDownItme);
          this.countDownItme = null;
          this.audioImg = false;
          this.countDownTime = this.audioDuration;
        };
      } else {
        this.audio.pause();
        this.audioImg = false;
        clearInterval(this.countDownItme);
        this.countDownItme = null;
        this.countDownTime = this.audioDuration;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.audio-box {
  width: 180px;
  height: 26px;
  display: flex;
  align-items: center;
  background: rgba(#006de7, 0.2);
  border-radius: 15px 15px 15px 15px;
  padding: 0 10px;
  margin-top: 10px;
  .audio-icon {
    width: 15px;
    height: 15px;
    margin-right: 7px;
  }
  .play {
    width: 102px;
    height: 14px;
    margin-right: 9px;
  }
  & > span {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #006de7;
  }
}
</style>
