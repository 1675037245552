<!--
 * @Author:
 * @Date: 2023-03-24 15:33:10
 * @LastEditTime: 2023-06-01 16:02:16
 * @LastEditors: 王有毒
 * @Description:
 * @FilePath: \edub_tob_pc\src\views\seventhPage\mine\learn\myOperationDetailsDialog.vue
 *
-->
<template>
  <!-- 作业提交弹窗 -->
  <el-dialog class="myOperationDetails_dialog" title="提交" :visible.sync="$parent.sumbitDialogVisible">
    <p class="myOperationDetails_dialog_content">
      {{ $parent.assignmentContent }}
    </p>
    <div class="myOperationDetails_dialog_btnBox">
      <el-button class="myOperationDetails_dialog_btnBox_left" @click="$parent.sumbitDialogVisible = false">取
        消</el-button>
      <el-button :loading="loading" class="myOperationDetails_dialog_btnBox_right" @click="submitOperation">确
        定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'MyOperationDetailsDialog',
  props: {},
  data() {
    return {
      loading: false
    }
  },
  created() {
    console.log('生成了组件')
  },
  methods: {
    // 提交作业
    async submitOperation() {
      this.loading = true
      await this.$parent.save(true)
      // this.$parent.getStudentWorkDetail();
      this.$parent.sumbitDialogVisible = false
      if (this.$parent.StudentWorkDetail.commitStatus) {
        await this.$router.go(-1)
      }
      this.loading = false
      console.log('submitOperation', '提交作业')
    },
    // 获取作业列表
    getOperation() {
      console.log('getOperation', '获取作业')
    }
  }
}
</script>

<style lang="less" scoped>
.myOperationDetails {
  width: 1200px;
  font-size: 18px;

  .myOperationDetails_dialog {
    /deep/ .el-dialog__body {
      padding-top: 53px !important;
      height: 213px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;

      .myOperationDetails_dialog_content {
        height: 22px;
        font-size: 16px;
        text-align: center;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 27px;
      }

      .myOperationDetails_dialog_btnBox {
        margin-top: 72px;
        display: flex;
        justify-content: center;
        align-items: center;

        .myOperationDetails_dialog_btnBox_right {
          color: #ffffff;
          width: 140px;
          height: 44px;
          background: #1c81f5;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
        }
      }

      .myOperationDetails_dialog_btnBox_left {
        margin-right: 10px;
        width: 139px;
        height: 43px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dddddd;
      }
    }

    /deep/.el-dialog {
      width: 420px;
      height: 276px;
      background: #ffffff;
      margin: 14% auto 0 !important;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
    }

    /deep/ .el-dialog__header {
      text-align: center;
      padding-top: 29px;
    }
  }
}
</style>
